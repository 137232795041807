import React from "react";
import { Helmet } from "react-helmet";

import logo from '../../../assets/image/logo/logo.png';

import Header from "../Header";
export default function Layout({
  children,
}) {
  return (
    <>
    <Helmet>
          <title>GasGas Analytics</title>
          <link rel="icon" type="image/png" href={logo} />
    </Helmet>
      
      {children}
    </>
  )
}
